import {
  Button,
  Col,
  DatePicker,
  Input,
  Layout,
  Pagination,
  Popover,
  Row,
  Select,
  Typography,
} from "antd";
import React, { useEffect, useState } from 'react';

import { FaPlus } from "react-icons/fa";
import { IoSearchSharp } from "react-icons/io5";
import { useNavigate } from 'react-router';
import Card from "../../components/card";
import frameImage from "../../images/Frame.png";

import { FaFilter, FaSearch } from "react-icons/fa";
import { useSelector } from "react-redux";
import swal from "sweetalert";
import { Get } from "../../config/api/get";
import { COMMUNITY } from "../../config/constants/api";


export default function Community() {
  const navigate=useNavigate()

  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(true);
  const token = useSelector((state) => state.user.userToken);
  const [allPost ,setAllPost] = useState([])
  const [open, setOpen] = useState(false);
  

    function search() {
      alert(value)
    }


    const course = {
      title: 'Topic Name',
      description: 'Welcome to Teyvat, Traveler! This is the place to discuss with others about your favorite game: Genshin Impact!',
      name: 'Course Name',
      members: 90 ,
      image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS-C_UAhXq9GfuGO452EEzfbKnh1viQB9EDBQ&s',
      _id : '123'
    }

    // pagindation
    const [paginationConfig, setPaginationConfig] = useState({
      pageNumber: 1,
      limit: 15,
      totalDocs: 0,
      totalPages: 0,
    });


     const getPosts = async (pageNumber, pageSize, search, reset = false) => {
        setLoading(true);
        try {
          const response = await Get(COMMUNITY.getAllPost, token, {
            page: pageNumber
              ? pageNumber.toString()
              : paginationConfig.pageNumber.toString(),
            limit: pageSize
              ? pageSize.toString()
              : paginationConfig.limit.toString(),
            status: reset ? "" : filter.status || null,
            type: reset ? "" : filter.type || null,
            keyword: search ? search : null,
            from: reset ? "" : filter?.from ? filter?.from.toISOString() : "",
            to: reset ? "" : filter?.to ? filter?.to.toISOString() : "",
          });


          console.log("response", response);
          
          if (response.status === true) {
            setLoading(false);
            setAllPost(response?.data);
            setPaginationConfig({
              pageNumber: response?.data?.page,
              limit: response?.data?.limit,
              totalDocs: response?.data?.totalDocs,
              totalPages: response?.data?.totalPages,
            });
          } else {
            swal("Error", "Something went wrong", "error")
            console.log("error====>", response);
          }
        } catch (error) {
          swal("Error", error?.message || "something went wrong" , "error");
          console.log(error);
          setLoading(false);
        }
      };

      useEffect(() => {
        getPosts()
      },[])

    const [filter, setFilter] = useState({
      status: null,
      type: null,
      keyword: "",
      from: null,
      to: null,
    });
  
    const startIndex =
      (paginationConfig.pageNumber - 1) * paginationConfig.limit + 1;
    const endIndex = Math.min(
      startIndex + paginationConfig.limit - 1,
      paginationConfig.totalDocs
    );
    const message = `Showing records ${endIndex} of ${paginationConfig.totalDocs}`;


    const handleOpenChange = (newOpen) => {
      setOpen(newOpen);
    };

    const handlePageChange = (pageNumber) => {
      setPaginationConfig({
        ...paginationConfig,
        pageNumber: pageNumber,
      });
  
      getPosts(pageNumber);
    };
  
    const handleSearch = (value) => {
      setFilter({
        ...filter,
        keyword: value,
      });
    };
  
    const handleStatusChange = (value) => {
      setFilter({
        ...filter,
        status: value,
      });
    };
  
    const handleTypeChange = (value) => {
      setFilter({
        ...filter,
        type: value,
      });
    };
  
    const resetFilter = () => {
      setFilter({
        status: null,
        keyword: "",
        from: null,
        to: null,
      });
      getPosts(paginationConfig.pageNumber, paginationConfig.limit, "", true);
    };
  
    const handleFrom = (date) => {
      setFilter({
        ...filter,
        from: date,
      });
    };
  
    const handleTo = (date) => {
      setFilter({
        ...filter,
        to: date,
      });
    };
  
    const handleLimitChange = (pageSize) => {
      setPaginationConfig({
        ...paginationConfig,
        limit: pageSize,
        current: 1,
      });
  
      getPosts(1, pageSize);
    };


    const itemRender = (_, type, originalElement) => {
      if (type === "prev") {
        return <a>Previous</a>;
      }
      if (type === "next") {
        return <a>Next</a>;
      }
      return originalElement;
    };


    const filterContent = (
      <div className="filterDropdown">
        <div>
          <p className="mainLabel" style={{ padding: "10px" }}>
            Filter
          </p>
        </div>
        <hr style={{ margin: 0 }} />
  
        <div className="filterDropdownBody">
          <p className="mainLabel">Lesson Date:</p>
          <DatePicker
            className="mainInput filterInput"
            value={filter.from}
            onChange={(e) => handleFrom(e)}
            placeholder="From"
          />
          <DatePicker
            className="mainInput filterInput"
            value={filter.to}
            onChange={(e) => handleTo(e)}
            placeholder="To"
          />
  
          <p className="mainLabel">Lesson Type:</p>
  
          <Select
            size={"large"}
            className="filterSelectBox"
            placeholder="Select Lesson Type"
            value={filter.type}
            onChange={(e) => handleTypeChange(e)}
            style={{
              width: "100%",
              marginBottom: "10px",
              textAlign: "left",
            }}
            options={[
              { value: "TUTORING", label: "Training" },
              { value: "COACHING", label: "Consulting" },
            ]}
          />
  
  <p className="mainLabel">Status:</p>
  
  <Select
    size={"large"}
    className="filterSelectBox"
    placeholder="Select Status"
    value={filter.status}
    onChange={(e) => handleStatusChange(e)}
    style={{
      width: "100%",
      marginBottom: "10px",
      textAlign: "left",
    }}
    options={[
      { value: "PENDING", label: "Pending" },
      { value: "UPCOMING", label: "Upcoming" },
    ]}
  />
  
  
          <Button
            type="primary"
           
            block
            size={"large"}
            style={{ marginBottom: "10px" }}
            className="loginButton"
            onClick={() => {
              getPosts()
            }}
          >
            Apply
          </Button>
          <Button
            type="primary"
           
            block
            size={"large"}
            className="loginButton"
            onClick={() => resetFilter()}
          >
            Clear All
          </Button>
        </div>
      </div>
    );

  return (
    <Layout style={{ minHeight: "100vh" }}>

     
    <Row
      className="blueBackground"
      style={{
        height: "45vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Typography.Title
        className="fontFamily1"
        style={{
          fontSize: "30px",
          color: "#333d47",
          marginTop: 20,
          marginBottom: 20,
        }}
      >
        {<> Community</>}
      </Typography.Title>
    </Row>


    <Row className='md:mx-24 my-10'>
    <div  className="bg-cover bg-center px-10 sm:px-24 w-full" style={{ backgroundImage: `url(${frameImage})` , height:"400px" }}>

      <div className='flex w-full h-full justify-center flex-col gap-5 items-center'>

      <h2 className='text-4xl md:text-5xl whitespace-nowrap text-white font-extrabold'>Find your community</h2>
      <h2 className='text-2xl md:text-3xl text-white font-semibold'>From education, learning, there's a place for you.</h2>

      <div className='relative'>

        <input
        type='text'
        placeholder='Explore Discussion'
        className='w-[280px] md:w-[500px] h-12 focus:ring-0 focus:outline-none  p-6 rounded-lg border-2 border-gray-300'
        value={value}
        onChange={e => setValue(e.target.value)}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            search();
          }
        }}
        />

        <IoSearchSharp
        onClick={search}
        size={20} className='absolute right-2 top-3'/>

        </div>

      </div>


    </div>
    </Row>

    <Row className="mx-4 md:mx-24 mb-5" >
          <Col xs={24} md={12}>
          </Col>
          <Col
            xs={24}
            md={12}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
             <Popover
              content={filterContent}
              trigger="click"
              open={open}
              onOpenChange={handleOpenChange}
              placement="bottomRight"
              arrow={false}
            >
              <Button
               shape="circle"
                style={{
                  padding: "12px 12px 5px",
                  height: "auto",
                  backgroundColor: "#00a79d",
                }}
              >
                <FaFilter style={{ fontSize: "16px", color: "white" }} />
              </Button>
            </Popover>
            &emsp;
            <Input
              style={{ width: "250px" }}
              className="mainInput dashInput"
              placeholder="Search Here"
              onChange={(e) => handleSearch(e.target.value)}
              suffix={
                <FaSearch
                  style={{
                    color: "grey",
                    fontSize: 16,
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    getPosts(1, paginationConfig.limit, filter.keyword)
                  }
                />
              }
              onPressEnter={(e) =>
                getPosts(1, paginationConfig.limit, filter.keyword)
              }
            />
           
           
          </Col>
    </Row>


     {/* <Row className='md:mx-24 my-10 flex-row md:flex-row flex md:flex-wrap gap-7 justify-center md:justify-start items-center'> */}
     <Row className='md:mx-20 grid-Container'>
      {loading ? <>
          <div className="flex justify-center items-center  gap-5">
            <p className="text-3xl font-semibold">Loading</p>
        <div class="w-12 h-12 border-4 border-dashed border-t-4 border-blue-500  rounded-full animate-spin"></div>

          </div>
            </>
        :
        <>
        

        {allPost?.docs?.map((post, i) => ( 
          <Card
          post={post}
          key={i}
          />
        ))}


        </>}
      </Row>   


      <Row className="md:mx-24 my-10">
          <Col xs={24} md={12}>
            <p  className="fontFamily1 text-center my-3 md:my-0 md:text-start" style={{color:"#999"}}>{message}</p>
          </Col>
          <Col
            xs={24}
            md={12}
            className="flex md:justify-end justify-center"
          >
            <Pagination
              className="styledPagination"
              onChange={(e) => handlePageChange(e)}
              current={parseInt(paginationConfig.pageNumber)}
              pageSize={paginationConfig.limit}
              total={paginationConfig.totalDocs}
              itemRender={itemRender}
            />
          </Col>
        </Row>

  </Layout>
  )
}
