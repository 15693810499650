import React from 'react'
import frame2Image from "../../images/Frame2.png"
import { useNavigate } from 'react-router'
import { UPLOAD_URL2 } from '../../config/constants/api'



export default function Card({post}) {
  const navigate=useNavigate()

  return (
    <div 
    onClick={()=>navigate(`/community-post/${post?._id}`)}
    className='max-w-[400px] min-h-[270px] border-[1px] border-slate-200 pb-4 shadow-lg rounded-lg flex flex-col items-start gap-5 cursor-pointer'>

      <img
      src={post?.thumbnail
        ? `${UPLOAD_URL2}${post.thumbnail
        }` :  'https://reputationtoday.in/wp-content/uploads/2020/04/4d8dd-students-in-community.001.png'} 
      className='h-[200px] w-full'
      />
    <div className='flex flex-col gap-4 items-start px-5'>

      <div className='flex items-center gap-2'>
      <img src={frame2Image} className='h-8 w-8 rounded-full' />
      {/* Title */}
      <h2 className='text-xl font-semibold'>
        {post?.title}</h2>
      </div>


      {/* description */}
      <div dangerouslySetInnerHTML={{ __html: post.description?.slice(0, 110) + (post.description?.length > 30 ? " ......" : "") }} />
    </div>


      <div className='flex gap-2 justify-start items-center px-5'>
        <div className='w-4 h-4 bg-green-700 rounded-full' />

        
        <p className='font-semibold'>{post?.name}</p>
        <p>{post?.members.length}  {post.members.length > 1 ? "members" : "member"}</p>
      </div>



    </div>

  )
}
