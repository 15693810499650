import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import swal from "sweetalert";
import { PiDotsThreeOutlineFill } from "react-icons/pi";
import { Delete } from '../../config/api';
import { UPLOADS_URL } from '../../config/constants/api';
import moment from "moment";


export default function Comment({Comment :{text, createdAt,user,_id},postId,refetch}) {
  // console.log("post Id from comment =>", postId);
  const loggedUserId = useSelector((state) => state.user.userData._id);
  const [isHide ,setIsHide] = useState(true)
  const token = useSelector((state) => state.user.userToken);
  const [loader,setLoader]= useState(false)


  async function deleteComment(){
    setLoader(true)
    setIsHide(!isHide)
    try {
      const response = await Delete(`/community/${postId}/comment/`+_id, token,{});
      console.log("responst from delete => ",response)
      if (response?.status === true) {
        refetch()
        swal("Success", "Comment deleted successfully", "success")
        setLoader(false)

      }else {
        swal(
          "Oops!",
          response?.data?.message || response?.response?.data?.message,
          "error"
        );
        setLoader(false)
      }
    } catch (error) {
      setLoader(false)
      swal("Error","An error occurred while deleting the comment ")
      console.log(error);
    }
  }

  return (
    <>
     <div className="my-10 border-l-4 border-orange-500 shadow-slate p-2 w-full flex justify-between items-start pr-3">

        {loader ?
           <>
           <div className="w-full flex jus items-center p-4">
              <div class="w-16 h-16 border-4  border-dashed border-t-4 border-black rounded-full animate-spin"></div>
              </div>
           </>
        :

          <>
              <div className="p-4">
          <div className="flex justify-start items-center gap-4 ">
        <img 
        src= {user?.image? UPLOADS_URL +"/"+ user.image : "https://www.pngkey.com/png/full/114-1149878_setting-user-avatar-in-specific-size-without-breaking.png"}
        className="w-12 h-12 rounded-full"
        />
        <div className="flex flex-col justify-start items-start self-start gap-[-2px]">
          <p className="text-xs md:text-lg font-bold">{user?.firstName && user.firstName + " "+ user.lastName}</p>
          <p className='text-xs'> {moment(createdAt).format('MMMM Do YYYY, h:mm:ss a')}</p>
        </div>
            </div>

              <p className="mt-6 text-xs md:text-[16px] font-normal">
              {text}
              </p>

              </div>

              <div className={`${loggedUserId !== user?._id && "hidden" } relative`}>
                  <PiDotsThreeOutlineFill 
                    className="cursor-pointer"
                  onClick={() => setIsHide(!isHide)}
                  size={30} />

                  {!isHide &&
                  <div className="absolute top-10 transition-all duration-500 right-0 whitespace-nowrap p-3 text-2xl font-semibold flex flex-col gap-5 items-start bg-slate-200">
                     <p 
                    className="cursor-pointer md:text-[16px]"
                    onClick={() =>{
                      swal({
                        title: "Are you sure?",
                        text: "Once deleted, you will not be able to recover this!",
                        icon: "warning",
                        buttons: {
                          cancel: "Cancel",
                          confirm: "Yes, delete it!"
                        },
                        dangerMode: true,
                      })
                      .then((willDelete) => {
                        if (willDelete) deleteComment();
                      });
                    }}
                    >Delete Comment</p>
                  </div>
                  }

              </div>
              </>
        }



     </div>
    </>
  )
}
