import React from "react";
import {
  Form,
  Checkbox,
  Input,
  Col,
  Row,
  Select,
  Typography,
  Layout,
  Card,
  Button,
  Space,
} from "antd";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { Post } from "../../config/api/post";
import { AUTH } from "../../config/constants/api";
import { addUser, removeUser } from "../../redux/slice/authSlice";
import swal from "sweetalert";

//icons
import { IoMdPin } from "react-icons/io";
import { FaEnvelope } from "react-icons/fa";
import { BsFillTelephoneFill } from "react-icons/bs";
import { FiMail, FiLock } from "react-icons/fi";

function Signup() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.userData);
  const token = useSelector((state) => state.user.userToken);
  const { Option } = Select;
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (user && token) {
      navigate("/dashboard", { replace: true });
    }
  }, [user, token]);

  const onFinish = (values) => {
    debugger;
    try {
      console.log("Success:", values);

      const day = parseInt(values.birthday);
      const month = parseInt(values.birthmonth) - 1; // Months are zero-based in JavaScript (0-11)
      const year = parseInt(values.birthyear);

      const birthday = new Date(year, month, day);

      let data = {
        firstName: values?.firstName,
        lastName: values?.lastName,
        email: values?.email,
        password: values?.password,
        city: values?.city,
        birthday: birthday,
        parent: values?.parent,
        phoneNumber: values?.phone,
        homeNumber: values?.homeNumber,
        school: values?.school,
        gradeLevel: values?.grade,
        city: values?.city,
        state: values?.state,
        zip: values?.zip,
        // subjects: values?.subjects?.split(",")?.map((value) => value?.trim()),
        subjects: values?.subjects,
      };
      setLoading(true);
      Post(AUTH.signup, data)
        .then((response) => {
          setLoading(false);

          console.log(response, "this is response response");

          if (response?.data?.status) {
            swal("Success", response?.data?.message, "success");
            navigate("/signin", { replace: true });
          } else {
            swal(
              "Oops!",
              response?.data?.message || response?.response?.data?.message,
              "error"
            );
          }
        })
        .catch((e) => {
          console.log(e, "ww");
          swal("Oops!", "internal server error", "error");
          setLoading(false);
        });
    } catch (error) {
      debugger
      console.log(error);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  const options = [
    {
      label: "B2B Customer Portals",
      value: "b2B Customer Portals",
      desc: "B2B Customer Portals",
    },
    {
      label: "B2B Training",
      value: "b2B Training",
      desc: "B2B Training",
    },
    {
      label: "Compliance Training",
      value: "compliance Training",
      desc: "Compliance Training",
    },
    {
      label: "Continuing Education",
      value: "continuing Education",
      desc: "Continuing Education",
    },
    {
      label: "Employee Development",
      value: "employee Development",
      desc: "Employee Development",
    },
    {
      label: "Learner Reports",
      value: "learner Reports",
      desc: "Learner Reports",
    },
    {
      label: "Instructor Led Training",
      value: "instructor Led Training",
      desc: "Instructor Led Training",
    },
  ];

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Row
        className="signupBackground"
        style={{
          height: "45vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography.Title
          className="fontFamily1 signupBackground-content"
          style={{ fontSize: "30px", color: "#000" }}
        >
          {<> Register</>}
        </Typography.Title>
      </Row>

      {/* section 2 */}
      <Row
        className="whiteBackground"
        style={{ backgroundColor: "white", justifyContent: "center" }}
      >
        <Col xs={24} md={20}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",

              padding: "50px 20px",
            }}
          >
            <Card
              className="infoBox"
              bordered={false}
              style={{
                width: "100%",
                padding: "20px",
              }}
            >
              <Typography.Title
                className="fontFamily1"
                style={{
                  fontSize: "25px",
                  fontWeight: 600,
                  color: "black",
                  textAlign: "left",
                  marginTop: 0,
                }}
              >
                Personal Information
              </Typography.Title>

              {/* <Form
                layout="vertical"
                name="basic"
                className="contactForm"
                labelCol={{
                  span: 0,
                }}
                wrapperCol={{
                  span: 24,
                }}
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Row gutter={20}>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label="First Name*"
                      name="firstName"
                      rules={[
                        {
                          required: true,
                          message: "Please input your first name!",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder="Enter FullName"
                        className="signupFormInput"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label="Last Name*"
                      name="lastName"
                      rules={[
                        {
                          required: true,
                          message: "Please input your last name",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder="Enter LastName"
                        className="signupFormInput"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={20}>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label="Date of Birth*"
                      name="birthmonth"
                      rules={[
                        {
                          required: true,
                          message: "Please select birth month",
                        },
                      ]}
                    >
                      <Select
                        size="large"
                        className="signupSelectBox"
                        placeholder="Select Month"
                      >
                        <option value="01">January</option>
                        <option value="02">February</option>
                        <option value="03">March</option>
                        <option value="04">April</option>
                        <option value="05">May</option>
                        <option value="06">June</option>
                        <option value="07">July</option>
                        <option value="08">August</option>
                        <option value="09">September</option>
                        <option value="10">October</option>
                        <option value="11">November</option>
                        <option value="12">December</option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Row gutter={20}>
                      <Col xs={24} md={12}>
                        <Form.Item
                          label=" "
                          name="birthday"
                          rules={[
                            {
                              required: true,
                              message: "Please select birth day",
                            },
                          ]}
                        >
                          <Select
                            size="large"
                            className="signupSelectBox"
                            placeholder="Select Day"
                          >
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                            <option value="13">13</option>
                            <option value="14">14</option>
                            <option value="15">15</option>
                            <option value="16">16</option>
                            <option value="17">17</option>
                            <option value="18">18</option>
                            <option value="19">19</option>
                            <option value="20">20</option>
                            <option value="21">21</option>
                            <option value="22">22</option>
                            <option value="23">23</option>
                            <option value="24">24</option>
                            <option value="25">25</option>
                            <option value="26">26</option>
                            <option value="27">27</option>
                            <option value="28">28</option>
                            <option value="29">29</option>
                            <option value="30">30</option>
                            <option value="31">31</option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={12}>
                        <Form.Item
                          label=" "
                          name="birthyear"
                          rules={[
                            {
                              required: true,
                              message: "Please select birth year",
                            },
                          ]}
                        >
                          <Select
                            size="large"
                            className="signupSelectBox"
                            placeholder="Select Year"
                          >
                            {Array.from({ length: 2024 - 1980 }, (_, index) => (
                              <option key={index} value={1980 + index}>
                                {1980 + index}
                              </option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row gutter={20}>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label="Parent / Guardian Name*"
                      name="parent"
                      rules={[
                        {
                          required: true,
                          message: "Please input parent / Guardian name!",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder="Enter Parent / Guardian Name"
                        className="signupFormInput"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label="Email Address*"
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Please input your email",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder="Enter Email Address"
                        className="signupFormInput"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={20}>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label="Phone Number*"
                      name="phone"
                      rules={[
                        {
                          required: true,
                          message: "Please input Phone Number!",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder="Enter Phone Number"
                        className="signupFormInput"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label="Phone Number*"
                      name="homeNumber"
                      rules={[
                        {
                          required: true,
                          message: "Please input phone number",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder="Enter phone number"
                        className="signupFormInput"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={20}>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label="School Name*"
                      name="school"
                      rules={[
                        {
                          required: true,
                          message: "Please input school name",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder="Enter School Name"
                        className="signupFormInput"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label="Grade Level*"
                      name="grade"
                      rules={[
                        {
                          required: true,
                          message: "Please input grade Level",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder="Enter Grade"
                        className="signupFormInput"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={20}>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label="City*"
                      name="city"
                      rules={[
                        {
                          required: true,
                          message: "Please input city name",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder="Enter City"
                        className="signupFormInput"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label="State*"
                      name="state"
                      rules={[
                        {
                          required: true,
                          message: "Please input state",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder="Enter State"
                        className="signupFormInput"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={20}>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label="Zip"
                      name="zip"
                      rules={[
                        {
                          required: true,
                          message: "Please input zip code",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder="Enter Zip Code"
                        className="signupFormInput"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label="Intrested Subjects*"
                      name="subjects"
                      rules={[
                        {
                          required: true,
                          message: "Enter Intrested Subjects",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder="Enter Intrested Subjects (comma seperated)"
                        className="signupFormInput"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <br />
                <Row>
                  <Form.Item
                    name="remember"
                    valuePropName="checked"
                    style={{ marginBottom: 0, color: "white" }}
                  >
                    <Checkbox style={{ marginBottom: 0, color: "grey" }}>
                      {" "}
                      <p className="fontFamily1" style={{ margin: 0 }}>
                        By selecting this, you are confirming that you have read
                        and agree to wrightCo Academy's Terms and Conditions and
                        Privacy Policies
                      </p>
                    </Checkbox>
                  </Form.Item>
                </Row>
                <br />

                <Typography.Title
                  className="fontFamily1"
                  style={{
                    fontSize: "25px",
                    fontWeight: 600,
                    color: "black",
                    textAlign: "left",
                    marginTop: 0,
                  }}
                >
                  Login Credentials
                </Typography.Title>

                <Row gutter={20}>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label="Password*"
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "Please input your password!",
                        },
                      ]}
                    >
                      <Input.Password
                        size="large"
                        placeholder="Enter Password"
                        className="signupFormInput"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label="Confirm Password*"
                      name="confirmPassword"
                      rules={[
                        {
                          required: true,
                          message: "Please confirm your password",
                        },
                      ]}
                    >
                      <Input.Password
                        size="large"
                        placeholder="Enter Confirm Password"
                        className="signupFormInput"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <br />

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="loginButton"
                  >
                    {loading ? "Loading..." : "Register"}
                  </Button>
                </Form.Item>
              </Form> */}

              <Form
                layout="vertical"
                name="basic"
                className="contactForm"
                labelCol={{
                  span: 0,
                }}
                wrapperCol={{
                  span: 24,
                }}
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Row gutter={20}>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label="First Name*"
                      name="firstName"
                      rules={[
                        {
                          required: true,
                          message: "Please input your first name!",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder="First Name"
                        className="signupFormInput"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label="Last Name*"
                      name="lastName"
                      rules={[
                        {
                          required: true,
                          message: "Please input your last name",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder="Last Name"
                        className="signupFormInput"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                {/* <Row gutter={20}>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label="Date of Birth*"
                      name="birthmonth"
                      rules={[
                        {
                          required: true,
                          message: "Please select birth month",
                        },
                      ]}
                    >
                      <Select
                        size="large"
                        className="signupSelectBox"
                        placeholder="Select Month"
                      >
                        <option value="01">January</option>
                        <option value="02">February</option>
                        <option value="03">March</option>
                        <option value="04">April</option>
                        <option value="05">May</option>
                        <option value="06">June</option>
                        <option value="07">July</option>
                        <option value="08">August</option>
                        <option value="09">September</option>
                        <option value="10">October</option>
                        <option value="11">November</option>
                        <option value="12">December</option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Row gutter={20}>
                      <Col xs={24} md={12}>
                        <Form.Item
                          label=" "
                          name="birthday"
                          rules={[
                            {
                              required: true,
                              message: "Please select birth day",
                            },
                          ]}
                        >
                          <Select
                            size="large"
                            className="signupSelectBox"
                            placeholder="Select Day"
                          >
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                            <option value="13">13</option>
                            <option value="14">14</option>
                            <option value="15">15</option>
                            <option value="16">16</option>
                            <option value="17">17</option>
                            <option value="18">18</option>
                            <option value="19">19</option>
                            <option value="20">20</option>
                            <option value="21">21</option>
                            <option value="22">22</option>
                            <option value="23">23</option>
                            <option value="24">24</option>
                            <option value="25">25</option>
                            <option value="26">26</option>
                            <option value="27">27</option>
                            <option value="28">28</option>
                            <option value="29">29</option>
                            <option value="30">30</option>
                            <option value="31">31</option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={12}>
                        <Form.Item
                          label=" "
                          name="birthyear"
                          rules={[
                            {
                              required: true,
                              message: "Please select birth year",
                            },
                          ]}
                        >
                          <Select
                            size="large"
                            className="signupSelectBox"
                            placeholder="Select Year"
                          >
                            {Array.from({ length: 2024 - 1980 }, (_, index) => (
                              <option key={index} value={1980 + index}>
                                {1980 + index}
                              </option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row> */}

                <Row gutter={20}>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label="Email Address*"
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Please input your email",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder="Enter Email Address"
                        className="signupFormInput"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label="Phone Number*"
                      name="phone"
                      rules={[
                        {
                          required: true,
                          message: "Please input Phone number",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder="Enter Phone Number"
                        className="signupFormInput"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={20}>
                  {/* First Form Item - Half Width */}
                  <Col xs={24} md={12}>
                    <Form.Item
                      label="School Name*"
                      name="school"
                      rules={[
                        {
                          required: true,
                          message: "Please input Organization Name",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder="Organization Name"
                        className="signupFormInput"
                      />
                    </Form.Item>
                  </Col>

                  {/* Nested Column for Two Items (Each One Quarter of the Row) */}
                  <Col xs={24} md={12}>
                    <Row gutter={20}>
                      {/* Phone Number - Quarter Width */}
                      <Col xs={24} md={12}>
                        <Form.Item
                          label="organization Phone Number*"
                          name="organizationPhoneNumber"
                          rules={[
                            {
                              required: true,
                              message: "Please Input organization Phone Number",
                            },
                          ]}
                        >
                          <Input
                            size="large"
                            placeholder="Enter organization Phone number"
                            className="signupFormInput"
                          />
                        </Form.Item>
                      </Col>

                      {/* Service Area - Quarter Width */}
                      <Col xs={24} md={12}>
                        <Form.Item
                          label="Your Service Area*"
                          name="birthmonth"
                          rules={[
                            {
                              required: false,
                              message: "Please select birth month",
                            },
                          ]}
                        >
                          <Select
                            size="large"
                            className="signupSelectBox"
                            placeholder="Select Service Area"
                          >
                            <option value="01">Board Member</option>
                            <option value="02">Policy Council</option>
                            <option value="03">Administration</option>
                            <option value="04">Teacher</option>
                            <option value="05">Family Services</option>
                            <option value="06">Nutrition</option>
                            <option value="07">Custodial</option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                {/*  Post zip city */}
                <Row gutter={20}>
                  {/* City */}
                  <Col xs={24} md={8}>
                    <Form.Item
                      label="City*"
                      name="city"
                      rules={[
                        {
                          required: true,
                          message: "Please input city name",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder="City"
                        className="signupFormInput"
                      />
                    </Form.Item>
                  </Col>

                  {/* State */}
                  <Col xs={24} md={8}>
                    <Form.Item
                      label="State*"
                      name="state"
                      rules={[
                        {
                          required: true,
                          message: "Please input state",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder="State"
                        className="signupFormInput"
                      />
                    </Form.Item>
                  </Col>

                  {/* Zip */}
                  <Col xs={24} md={8}>
                    <Form.Item
                      label="Zip"
                      name="zip"
                      rules={[
                        {
                          required: true,
                          message: "Please input zip code",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder="Zip Code"
                        className="signupFormInput"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={20}>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label="Intrested Subjects*"
                      name="subjects"
                      rules={[
                        {
                          required: true,
                          message: "Enter Intrested Subjects",
                        },
                      ]}
                    >
                      <Select
                        mode="multiple"
                        className="signupSelectBox"
                        placeholder="select Course"
                        defaultValue={[""]}
                        onChange={handleChange}
                        options={options}
                        optionRender={(option) => (
                          <Space>
                            <span role="img" aria-label={option.data.label}>
                              {option.data.emoji}
                            </span>
                            {option.data.desc}
                          </Space>
                        )}
                      />
                      {/* <Input
                        size="large"
                        placeholder="Enter Intrested Subjects (comma seperated)"
                        className="signupFormInput"
                      /> */}
                    </Form.Item>
                  </Col>
                </Row>

                <br />
                <Row>
                  <Form.Item
                    name="remember"
                    valuePropName="checked"
                    style={{ marginBottom: 0, color: "white" }}
                  >
                    <Checkbox style={{ marginBottom: 0, color: "grey" }}>
                      {" "}
                      <p className="fontFamily1" style={{ margin: 0 }}>
                        By selecting this, you are confirming that you have read
                        and agree to wrightCo Academy's Terms and Conditions and
                        Privacy Policies
                      </p>
                    </Checkbox>
                  </Form.Item>
                </Row>
                <br />

                <Typography.Title
                  className="fontFamily1"
                  style={{
                    fontSize: "25px",
                    fontWeight: 600,
                    color: "black",
                    textAlign: "left",
                    marginTop: 0,
                  }}
                >
                  Login Credentials
                </Typography.Title>

                <Row gutter={20}>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label="Password*"
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "Please input your password!",
                        },
                      ]}
                    >
                      <Input.Password
                        size="large"
                        placeholder="Enter Password"
                        className="signupFormInput"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label="Confirm Password*"
                      name="confirmPassword"
                      rules={[
                        {
                          required: true,
                          message: "Please confirm your password",
                        },
                      ]}
                    >
                      <Input.Password
                        size="large"
                        placeholder="Enter Confirm Password"
                        className="signupFormInput"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <br />

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="loginButton"
                  >
                    {loading ? "Loading..." : "Register"}
                  </Button>
                </Form.Item>
              </Form>

              <Typography.Text
                className="fontFamily1"
                style={{
                  fontSize: "14px",
                  color: "#3C3C3B",
                  textAlign: "left",
                  marginTop: 0,
                  marginBottom: 30,
                }}
              >
                <>
                  Already have an account{" "}
                  <span
                    onClick={() => navigate("/signin")}
                    style={{
                      cursor: "pointer",
                      fontWeight: "bold",
                      textDecoration: "underline",
                    }}
                  >
                    Login Now
                  </span>
                </>
              </Typography.Text>
            </Card>
          </div>
        </Col>
      </Row>
    </Layout>
  );
}

export default Signup;
