import React, { useState } from "react";
// import AuthLayout from "../../components/";
import {
  Col,
  Row,
  Typography,
  List,
  Form,
  Layout,
  Input,
  Button,
  Card,
  Tabs,
  Table,
  Image,
  Divider,
  Rate,
} from "antd";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { Post } from "../../config/api/post";
import { AUTH, UPLOADS_URL } from "../../config/constants/api";
import { addUser, removeUser } from "../../redux/slice/authSlice";
import { PiMedal } from "react-icons/pi";
import { TiArrowSortedDown } from "react-icons/ti";
import {
  FaArrowRight,
  FaCheck,
  FaCheckCircle,
  FaMedal,
  FaRegClock,
  FaRegStar,
} from "react-icons/fa";
import { TbBolt } from "react-icons/tb";
import { LiaBrainSolid } from "react-icons/lia";
import swal from "sweetalert";
import { ImageUrl } from "../../helpers/Function";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoIosMail } from "react-icons/io";
const { Meta } = Card;

// import router from "next/router";
const data6 = [
  {
    avatar: <FaCheckCircle />,
    title: "All Core Features",
  },
  {
    avatar: <FaCheckCircle />,
    title: "Build Custom features. Limits Apply",
  },
  {
    avatar: <FaCheckCircle />,
    title: "24-7 helpdesk support and Premium Customer Success team",
  },
];
const onChange = (key) => {
  console.log(key);
};
const items = [
  {
    key: "1",
    label: "Pricing For Cource",
    children: (
      <>
        <div className="price-flexbox">
          <div className="price-cards" style={{ minHeight: "473px" }}>
            <h4 className="text-38 text-white">Plan Name</h4>
            <h4 className="text-38 text-white">$ 35</h4>
            <p className="text-p-16 text-white">
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
              commodo ligula eget dolor.
            </p>
            {/* <p className="text-p-16 text-white">Starts at $999/Month</p> */}
            <div className="list-box">
              <List
                itemLayout="horizontal"
                dataSource={data6}
                renderItem={(item, index) => (
                  <List.Item>
                    <List.Item.Meta
                      avatar={<span className="list-icon">{item.avatar}</span>}
                      title={<h6 className="list-h6">{item.title}</h6>}
                    />
                  </List.Item>
                )}
              />
            </div>
            <Button
              type="primary"
              htmlType="submit"
              className="whiteButton"
              //   onClick={() => navigate("/about-us")}
              style={{ marginLeft: "5px" }}
            >
              Get Started
            </Button>
          </div>
          <div className="price-cards" style={{ minHeight: "473px" }}>
            <h4 className="text-38 text-white">Plan Name</h4>
            <h4 className="text-38 text-white">$ 50</h4>
            <p className="text-p-16 text-white">
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
              commodo ligula eget dolor.
            </p>
            {/* <p className="text-p-16 text-white">Starts at $999/Month</p> */}
            <div className="list-box">
              <List
                itemLayout="horizontal"
                dataSource={data6}
                renderItem={(item, index) => (
                  <List.Item>
                    <List.Item.Meta
                      avatar={<span className="list-icon">{item.avatar}</span>}
                      title={<h6 className="list-h6">{item.title}</h6>}
                    />
                  </List.Item>
                )}
              />
            </div>
            <Button
              type="primary"
              htmlType="submit"
              className="whiteButton"
              //   onClick={() => navigate("/about-us")}
              style={{ marginLeft: "5px" }}
            >
              Get Started
            </Button>
          </div>
          <div className="price-cards" style={{ minHeight: "473px" }}>
            <h4 className="text-38 text-white">Plan Name</h4>
            <h4 className="text-38 text-white">$ 50</h4>
            <p className="text-p-16 text-white">
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
              commodo ligula eget dolor.
            </p>
            {/* <p className="text-p-16 text-white">Starts at $999/Month</p> */}
            <div className="list-box">
              <List
                itemLayout="horizontal"
                dataSource={data6}
                renderItem={(item, index) => (
                  <List.Item>
                    <List.Item.Meta
                      avatar={<span className="list-icon">{item.avatar}</span>}
                      title={<h6 className="list-h6">{item.title}</h6>}
                    />
                  </List.Item>
                )}
              />
            </div>
            <Button
              type="primary"
              htmlType="submit"
              className="whiteButton"
              //   onClick={() => navigate("/about-us")}
              style={{ marginLeft: "5px" }}
            >
              Get Started
            </Button>
          </div>
        </div>
      </>
    ),
  },
  {
    key: "2",
    label: "Price For enterprise",
    children: (
        <>
          <div className="price-flexbox">
            <div className="price-cards" style={{ minHeight: "473px" }}>
              <h4 className="text-38 text-white">Plan Name</h4>
              <h4 className="text-38 text-white">$ 35</h4>
              <p className="text-p-16 text-white">
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
                commodo ligula eget dolor.
              </p>
              {/* <p className="text-p-16 text-white">Starts at $999/Month</p> */}
              <div className="list-box">
                <List
                  itemLayout="horizontal"
                  dataSource={data6}
                  renderItem={(item, index) => (
                    <List.Item>
                      <List.Item.Meta
                        avatar={<span className="list-icon">{item.avatar}</span>}
                        title={<h6 className="list-h6">{item.title}</h6>}
                      />
                    </List.Item>
                  )}
                />
              </div>
              <Button
                type="primary"
                htmlType="submit"
                className="whiteButton"
                //   onClick={() => navigate("/about-us")}
                style={{ marginLeft: "5px" }}
              >
                Get Started
              </Button>
            </div>
            <div className="price-cards" style={{ minHeight: "473px" }}>
              <h4 className="text-38 text-white">Plan Name</h4>
              <h4 className="text-38 text-white">$ 50</h4>
              <p className="text-p-16 text-white">
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
                commodo ligula eget dolor.
              </p>
              {/* <p className="text-p-16 text-white">Starts at $999/Month</p> */}
              <div className="list-box">
                <List
                  itemLayout="horizontal"
                  dataSource={data6}
                  renderItem={(item, index) => (
                    <List.Item>
                      <List.Item.Meta
                        avatar={<span className="list-icon">{item.avatar}</span>}
                        title={<h6 className="list-h6">{item.title}</h6>}
                      />
                    </List.Item>
                  )}
                />
              </div>
              <Button
                type="primary"
                htmlType="submit"
                className="whiteButton"
                //   onClick={() => navigate("/about-us")}
                style={{ marginLeft: "5px" }}
              >
                Get Started
              </Button>
            </div>
            <div className="price-cards" style={{ minHeight: "473px" }}>
              <h4 className="text-38 text-white">Plan Name</h4>
              <h4 className="text-38 text-white">$ 50</h4>
              <p className="text-p-16 text-white">
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
                commodo ligula eget dolor.
              </p>
              {/* <p className="text-p-16 text-white">Starts at $999/Month</p> */}
              <div className="list-box">
                <List
                  itemLayout="horizontal"
                  dataSource={data6}
                  renderItem={(item, index) => (
                    <List.Item>
                      <List.Item.Meta
                        avatar={<span className="list-icon">{item.avatar}</span>}
                        title={<h6 className="list-h6">{item.title}</h6>}
                      />
                    </List.Item>
                  )}
                />
              </div>
              <Button
                type="primary"
                htmlType="submit"
                className="whiteButton"
                //   onClick={() => navigate("/about-us")}
                style={{ marginLeft: "5px" }}
              >
                Get Started
              </Button>
            </div>
          </div>
        </>
      ),
  },
];

function Homepage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { Search } = Input;
  const user = useSelector((state) => state.user.userData);
  const token = useSelector((state) => state.user.userToken);
  const [loading, setLoading] = React.useState(false);

  // useEffect if user is already logged in
  React.useEffect(() => {
    if (user && token) {
      navigate("/", { replace: true });
    }
  }, [user, token]);

  const onFinish = (values) => {
    console.log("Success:", values);
    setLoading(true);

    let data = {
      email: values.email,
      password: values.password,
      devideId: "123456789",
    };
    Post(AUTH.signin, data)
      .then((response) => {
        setLoading(false);
        if (response?.data) {
          console.log("response", response.data.token);
          console.log("response", response.data.user);
          dispatch(
            addUser({ user: response.data.user, token: response.data.token })
          );
          navigate("/", { replace: true });
        } else {
          swal("Oops!", response.response.data.message, "error");
        }
      })
      .catch((e) => {
        console.log(":::;", e);
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const CustomRow = ({ faq }) => {
    const [expanded, setExpanded] = useState(false);

    const toggleExpand = () => {
      setExpanded(!expanded);
    };

    return (
      <Row justify="center">
        <Col xs={24} className="bg-white">
          <div className="flex flex-col justify-center items-center bg-[#00A79D] bg-opacity-70 mb-4 rounded-[10px] overflow-hidden">
            <div
              className="flex justify-between items-center px-6 py-4 w-full cursor-pointer bg-[#228a83]"
              onClick={toggleExpand}
            >
              <p className="font-popin text-2xl max-sm:text-xs text-white font-medium">
                {faq.text}
              </p>
              <TiArrowSortedDown
                className={`text-white min-w-[28px] min-h-[28px] pl-2 ${
                  expanded ? "transform rotate-180" : ""
                }`}
              />
            </div>
            <div
              className={`border-t border-white ${
                expanded
                  ? "max-h-[1000px] opacity-100 transition-all duration-500 px-6 py-4"
                  : "max-h-0 opacity-0 transition-all duration-500"
              }`}
            >
              <p className="font-euclid text-2xl max-sm:text-xs text-white font-normal leading-relaxed">
                {faq.innerText}
              </p>
            </div>
          </div>
        </Col>
      </Row>
    );
  };
  return (
    <Layout style={{ minHeight: "100vh", backgroundSize: "cover" }}>
      <Row
        className="blueBackground-old aboutbanner"
        style={{
          height: "55vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography.Title
          className="fontFamily1 aboutbanner-content"
          style={{ fontSize: "30px", color: "#000" }}
        >
          {<> Our Pricing Packages</>}
        </Typography.Title>
      </Row>
      <section className="bg-white top-bottom-padding">
        <Row
          justify={"center"}
          style={{ textAlign: "center", paddingBottom: "40px" }}
        >
          <Col xl={10} lg={12}>
            <Image
              preview={false}
              alt={"Failed to load image"}
              src={ImageUrl("miniImg4.png")}
            />
            <h4 className="text-38 ">Pricing Plans</h4>
            <p className="text-p-16 ">
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
              commodo ligula eget dolor.
            </p>
          </Col>
        </Row>

        <Row justify={"center"} style={{ padding: "10px 0", margin: "10px 0" }}>
          <Col lg={20}>
            <Tabs defaultActiveKey="1" items={items} onChange={onChange}className="price-tabs" />
          </Col>
        </Row>
      </section>
    </Layout>
  );
}

export default Homepage;
